import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight, FaList } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

import '../../css/Aktuelles/PostsStyle.css';
import '../../css/font-sizes.css';
import { motion } from 'framer-motion';
import config from '../../config';

const Posts = () => {
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState([0]);
    const [totalPosts, setTotalPosts] = useState([0]);

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    //Achtung: Muss auch im PHP-Skript angepasst werden
    const BeitraegeProSeite = 10;

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' }); // Beispiel: Maximal 768px breit gilt als Mobilgerät


    // Funktion zum Aktualisieren der Seite und der URL
    const handlePageChange = (newPage) => {
        // Aktualisiere die URL, ohne die Seite neu zu laden
        navigate(`?page=${newPage}`);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageParam = parseInt(searchParams.get('page') || '1', 10);

        // Setze die aktuelle Seite basierend auf dem URL-Parameter
        setPage(pageParam);

        const fetchPosts = async () => {
            setIsLoading(true); // Starte die Ladeanzeige
            try {
                const response = await fetch(`${config.apiUrl}/GetPosts.php?page=${pageParam}`);
                const data = await response.json();
                setPosts(data.posts); // oder setPosts(data), je nach Struktur deiner Antwort
                setTotalPages(data.totalPages);
                setTotalPosts(data.totalPosts);

            } catch (error) {
                console.error("Fehler beim Laden der Beiträge:", error);
            } finally {
                setIsLoading(false); // Beende die Ladeanzeige
            }
        };

        fetchPosts();
    }, [location.search]); // Abhängig von der Änderung der URL



    //Post öffnen 
    const openPost = (postId) => {
        navigate(`/post/${postId}?page=${page}`);
    };


    const getCharacterLimit = (className) => {
        switch (className) {
            case 'col-lg-12':
                return 700;
            case 'col-lg-8':
                return 500;
            case 'col-lg-6':
                return 400;
            case 'col-lg-4':
                return 200;
            default:
                return 200; // Standardwert, falls eine Klasse nicht explizit definiert ist
        }
    };

    // Funktion, um die ersten 100 Wörter des Textes zu extrahieren
    const getTeaser = (text, className) => {
        // Erstellen Sie ein neues DOMParser-Instanz
        const parser = new DOMParser();
        // Parsen Sie den HTML-String in ein DOM-Dokument
        const doc = parser.parseFromString(text, 'text/html');
        // Holen Sie sich alle <p>-Elemente
        const paragraphs = doc.querySelectorAll('p');
        // Extrahieren Sie den Text aus jedem <p>-Element
        const teaserText = Array.from(paragraphs).map(p => p.textContent).join(' ');

        // Bestimmen Sie die maximale Zeichenlänge basierend auf der Klasse
        const charLimit = getCharacterLimit(className);

        return teaserText.length > charLimit ? teaserText.substring(0, charLimit) + '...' : teaserText + '...';
    };

    const extractFirstImageSrc = (htmlContent) => {
        // Erstellen eines neuen DOMParser-Instanz
        const parser = new DOMParser();
        // Parsen des HTML-Strings in ein DOM-Dokument
        const doc = parser.parseFromString(htmlContent, 'text/html');
        // Holen des ersten <img>-Elements
        const imgElement = doc.querySelector('img');

        if (imgElement) {
            // Extrahieren der src-Eigenschaft
            let src = imgElement.getAttribute('src');

            // Entfernen von Backslashes vor Anführungszeichen
            src = src.replace(/\\"/g, '"');

            // Entfernen der Anführungszeichen am Anfang und am Ende der URL, falls vorhanden
            src = src.replace(/^"|"$/g, '');
            return src;
        }

        return null;
    };

    // Angenommene Struktur deiner ColKlassen
    const baseColClasses = [
        'col-lg-4', 'col-lg-8', // 1. und 2. Beitrag
        'col-lg-6', 'col-lg-6', // 3. und 4. Beitrag
        'col-lg-4', 'col-lg-4', 'col-lg-4', // 5., 6. und 7. Beitrag
        'col-lg-12', // 8. Beitrag
        'col-lg-8', 'col-lg-4' // 9. und 10. Beitrag
    ];

    // Anpassung für die letzte Seite
    const adjustColClassesForLastPage = (postsLength, page, totalPages) => {
        const colClasses = [...baseColClasses]; // Kopiere die Basis-Klassen
        if (page === totalPages && page > 1) {
            // Wenn auf der letzten Seite weniger Beiträge als erwartet sind,
            // stelle sicher, dass der letzte Beitrag col-sm-12 erhält.
            const lastIndex = postsLength - 1;
            colClasses[lastIndex] = 'col-sm-12';
            // Kürze das Array, falls notwendig
            return colClasses.slice(0, postsLength);
        }
        return colClasses;
    };

    //Anpassung des Datums
    const benutzerfreundlichesDatum = (datum) => {
        // Umwandlung in ein JavaScript-Datum
        const dateObject = new Date(datum);

        // Umwandlung in ein freundliches Format
        const friendlyDate = dateObject.toLocaleDateString('de-DE', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });

        return friendlyDate;
    }

    if (isLoading) {
        return (
            <div className="container">
                <div className="row text-center">
                    <div className="col-sm-12">
                        <h1 style={{ paddingTop: '10rem' }}>Lädt...</h1>
                    </div>
                </div>
            </div>
        );
    }

    const ColKlassen = adjustColClassesForLastPage(posts.length, page, totalPages);

    return (
        <div id='posts-wrapper'>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-sm-12">
                        <h5 className='text-muted'>Zeige {(page - 1) * BeitraegeProSeite + 1} - {Math.min(page * BeitraegeProSeite, totalPosts)} von {totalPosts} Beiträgen</h5>
                    </div>
                    {posts.map((post, index) => {
                        // Extrahieren Sie die Bild-URL aus dem editorContent
                        const bildUrl = extractFirstImageSrc(post.text);

                        return (

                            <div key={index} className={`${ColKlassen[index]} mb-4`} onClick={() => openPost(post.id)}>
                                <motion.div className="card" style={{ cursor: 'pointer' }}
                                    whileHover={{ scale: 1.03 }}
                                    whileTap={{ scale: 0.9 }}>
                                    {bildUrl && (
                                        <img src={bildUrl} className="card-img-top" alt={post.titel} style={{ objectFit: 'cover', maxHeight: '200px' }} />
                                    )}
                                    <div className="card-body d-flex flex-column">
                                        <h5 className="card-title">{post.titel}</h5>
                                        <h6 className="card-subtitle mb-2 text-muted">{post.autor} - {`${benutzerfreundlichesDatum(post.datum)}`}</h6>
                                        <hr style={{ color: 'var(--primary-color)' }} />
                                        <p className="card-text">{getTeaser(post.text, ColKlassen[index])}</p>
                                        <div className="mt-auto">
                                            <div className="d-flex justify-content-between">
                                                <p className="card-text my-2"><small className="text-muted">{post.hashtags}</small></p>
                                                <p className='text-muted my-2'>Lesedauer: {Math.floor((post.text.trim().split(/\s+/).length) / 100)} min.</p>
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                        );
                    })}

                </div>
                <hr style={{ color: 'var(--primary-color)' }} />
                {/* Desktop-Ansicht */}
                {!isMobile && (
                    <nav className='d-flex justify-content-center mt-5'>
                        <ul className="pagination">
                            <li className="page-item"><button className="page-link" onClick={() => handlePageChange(page - 1)} hidden={page === 1}>Vorherige</button></li>
                            <p className='px-5 pt-2' hidden={page === 1}>{page - 1}</p>
                            <p className='px-5 pt-2'><b>{page}</b></p>
                            <p className='px-5 pt-2' hidden={page >= totalPages}>{page + 1}</p>
                            <li className="page-item"><button className="page-link" onClick={() => handlePageChange(page + 1)} hidden={page >= totalPages}>Nächste</button></li>
                        </ul>
                    </nav>
                )}

                {/* Mobile Ansicht */}
                {isMobile && (
                    <nav className='d-flex justify-content-center mt-'>
                        <ul className="pagination">
                            <li className="page-item">
                                <button className="page-link" onClick={() => handlePageChange(page - 1)} hidden={page === 1}>
                                    <FaAngleLeft /> {/* Icon für vorherigen Beitrag */}
                                </button>
                            </li>
                            <p className='px-5 pt-2' hidden={page === 1}>{page - 1}</p>
                            <p className='px-5 pt-2'><b>{page}</b></p>
                            <p className='px-5 pt-2' hidden={page >= totalPages}>{page + 1}</p>
                            <li className="page-item">
                                <button className="page-link" onClick={() => handlePageChange(page + 1)} hidden={page >= totalPages}>
                                    <FaAngleRight /> {/* Icon für nächsten Beitrag */}
                                </button>
                            </li>
                        </ul>
                    </nav>
                )}
            </div>
        </div>
    );
};

export default Posts;
import React from 'react';
import '../css/ArrowAnimation.css'; // Importiere die CSS-Datei

const ArrowAnimation = () => {
    return (
      <div className="arrow-container">
        <img src="/img/arrow_down.png" alt="Arrow" className="arrow-icon" />
      </div>
    );
  };

export default ArrowAnimation;

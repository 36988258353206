import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/de';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import config from '../config';

import '../css/KalenderStyle.css';
moment.locale('de');
const localizer = momentLocalizer(moment);

const messages = {
  date: 'Datum',
  time: 'Uhrzeit',
  event: 'Ereignis',
  allDay: 'Ganztägig',
  week: 'Woche',
  work_week: 'Arbeitswoche',
  day: 'Tag',
  month: 'Monat',
  previous: 'Zurück',
  next: 'Weiter',
  yesterday: 'Gestern',
  tomorrow: 'Morgen',
  today: 'Heute',
  agenda: 'Agenda',
  noEventsInRange: 'Keine Ereignisse in diesem Zeitraum.',
  showMore: total => `+ ${total} mehr`,
};

const Kalender = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    // Events von der API abrufen
    fetch(`${config.apiUrl}/get-events.php`)
      .then(response => response.json())
      .then(data => {
        // Datenstruktur anpassen und sicherstellen, dass `start` und `end` gültige Date-Objekte sind
        const formattedEvents = data.map(event => ({
          title: event.title,
          start: new Date(event.start), // Sicherstellen, dass `start` ein Date-Objekt ist
          end: new Date(event.end),     // Sicherstellen, dass `end` ein Date-Objekt ist
          desc: event.desc,
        }));
        setEvents(formattedEvents);
      })
      .catch(error => console.error('Error fetching events:', error));
  }, []);


  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };
  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700}}
        defaultView="week"
        views={['week', 'month']}
        step={30}
        timeslots={2}
        min={new Date(2024, 5, 29, 7, 0, 0)}
        max={new Date(2024, 5, 29, 23, 59, 59)}
        selectable
        onSelectEvent={handleSelectEvent}
        eventPropGetter={(event) => ({
          style: { backgroundColor: 'var(--primary-color)' }
        })}
        messages={messages}
      />
      {selectedEvent && (
        <div className="info-card my-5" >
          <h2>{selectedEvent.title}</h2>
          <p><strong>Start:</strong> {new Date(selectedEvent.start).toLocaleString()}</p>
          <p><strong>Ende:</strong> {new Date(selectedEvent.end).toLocaleString()}</p>
          {selectedEvent.desc && (
            <div dangerouslySetInnerHTML={{ __html: selectedEvent.desc }} />
          )}
        </div>
      )}
    </div>
  );
};

export default Kalender;

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './css/font-sizes.css';

const ImpressumSeite = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <div id="impressum-wrapper" style={{ paddingTop: '8rem', minHeight: '100vh' }}>
            <Helmet>
                <title>Impressum | Limbach für Alle</title>
            </Helmet>
            <div className="container">
                <div className="row text-center">
                    <div className="col-sm-12">
                        <h1>Impressum</h1>
                        <hr style={{ color: 'var(--primary-color)' }} />
                    </div>

                    <div className="row my-5" style={{ textAlign: 'justify' }}>
                        <div class="col-sm-12">
                            <h2>Verein</h2>
                            <p>Soziale & politische Bildungsvereinigung Limbach-Oberfrohna e.V.<br />
                                Sachsenstraße 26<br />
                                09212 Limbach-Oberfrohna
                            </p>

                            <p><strong>Vertreten durch:</strong><br />
                                Elisa Grobe<br />
                            </p>

                            <h2 className='mt-5'>Kontakt</h2>
                            <p> E-Mail: info@limbach-fuer-alle.de</p>

                            <h2 className='mt-5'>Redaktionell verantwortlich</h2>
                            <p>Elisa Grobe<br />
                                Sachsenstraße 26<br />
                                09212 Limbach-Oberfrohna
                            </p>

                            <h2 className='mt-5'>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                                Verbraucherschlichtungsstelle teilzunehmen.</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};

export default ImpressumSeite;
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Post from './components/Aktuelles/Post';


import AktuellesSeite from './AktuellesSeite';
import VeranstaltungenSeite from './VeranstaltungenSeite';
import StandorteSeite from './StandorteSeite';
import KontaktSeite from './KontaktSeite';
import SpendeSeite from './SpendeSeite';
import IndexSeite from './IndexSeite';
import DatenschutzSeite from './DatenschutzSeite';
import ImpressumSeite from './ImpressumSeite';
import VereinSeite from './VereinSeite';
import MitmachenSeite from './MitmachenSeite';
import AngeboteSeite from './AngeboteSeite';
/*Admin-Panel Imports*/
import Login from './components/Admin/Login';
import AdminPanel from './AdminPanel';
import Upload from './components/Admin/Upload';
import Delete from './components/Admin/Delete';
import UploadEvent from './components/Admin/UploadEvent/UploadEvent';
import DeleteEvent from './components/Admin/DeleteEvent/DeleteEvent';
import Suchergebnisse from './components/Suchergebnisse';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        {/* Login für Admin-Panel */}
        <Route path="/login" element={<Login />} />

        <Route path="/admin"
          element={
            <ProtectedRoute>
              <AdminPanel />
            </ProtectedRoute>
          } />

        <Route path="/admin/upload"
          element={
            <ProtectedRoute>
              <Upload />
            </ProtectedRoute>
          } />

        <Route path="/admin/delete"
          element={
            <ProtectedRoute>
              <Delete />
            </ProtectedRoute>
          } />

        <Route path="/admin/upload-event"
          element={
            <ProtectedRoute>
              <UploadEvent />
            </ProtectedRoute>
          } />

        <Route path="/admin/delete-event"
          element={
            <ProtectedRoute>
              <DeleteEvent />
            </ProtectedRoute>
          } />

        <Route path="/" element={<IndexSeite />} />

        <Route path="/aktuelles" element={<AktuellesSeite />} />

        {/*Weiterleitung zum jeweiligen Post*/}
        <Route path="/post/:postId" element={<Post />} />
        <Route path="/veranstaltungen" element={<VeranstaltungenSeite />} />

        <Route path="/standorte" element={<StandorteSeite />} />

        <Route path="/angebote" element={<AngeboteSeite />} />
        <Route path="/mitmachen" element={<MitmachenSeite />} />
        <Route path="/verein" element={<VereinSeite />} />
        <Route path="/kontakt" element={<KontaktSeite />} />
        <Route path="/spende" element={<SpendeSeite />} />

        <Route path="/search" element={<Suchergebnisse />} />


        <Route path="/datenschutz" element={<DatenschutzSeite />} />
        <Route path="/impressum" element={<ImpressumSeite />} />
        {/* Das hier ist dann mit Protection
        <Route path="/post/:postId"
          element={
            <ProtectedRoute>
              <PostsPage />
            </ProtectedRoute>
          } />*/}

        {/* Das hier ist dann mit Protection
        <Route path="/post/:postId"
          element={
            <ProtectedRoute>
              <PostsPage />
            </ProtectedRoute>
          } />*/}

        {/* Definiere weitere Routen für deine Projekte */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Standorte from './components/Standorte'; 

import './css/StandorteStyle.css';

const StandorteSeite = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div id="standorte-seite-wrapper" style={{ paddingTop: '8rem', minHeight: '100vh' }}>
            <Helmet>
                <title>Standorte | Limbach für Alle</title>
            </Helmet>
            <div className="container">
                <div className="row text-center">
                    <div className="col-sm-12">
                        <h1>Unsere Standorte</h1>
                        <hr style={{ color: 'var(--primary-color)' }} />
                    </div>
                    <Standorte />
                </div>
            </div>
        </div>

    )
};

export default StandorteSeite;
import React from 'react';
import { Helmet } from 'react-helmet';
import HighlightText from './components/HighlightText';

const AngeboteSeite = () => {
    return (
        <div id="angebote-wrapper" style={{ paddingTop: '8rem', minHeight: '100vh' }}>
            <Helmet>
                <title>Unsere Angebote | Limbach für Alle</title>
            </Helmet>
            <div className="container">
                <div className="row text-center">
                    <div className="col-sm-12">
                        <h1>Unsere dauerhaften Angebote der Standorte </h1>
                        <hr style={{ color: 'var(--primary-color)' }} />
                        <p className='pt-4' style={{ textAlign: 'justify' }}>
                            Wir freuen uns, euch eine Vielzahl von regelmäßigen Angeboten und Aktivitäten in Limbach-Oberfrohna präsentieren zu können.
                            Egal ob ihr euch für Musik, gemeinsames Essen oder Unterstützung bei verschiedenen Anliegen interessiert, bei uns findet ihr sicherlich etwas Passendes.
                            Hier sind unsere aktuellen dauerhaften Angebote:
                        </p>
                    </div>

                    <div id='angebot1' className='col-sm-12 pt-5' style={{ textAlign: 'justify' }}>
                        <h3>Veranstaltungsraum Doro</h3>
                        <p>
                            Du stehst auf Live-Musik oder willst deine Lieblingsband nach Limbach holen?
                            Dann ist die Doro der perfekte Ort für dich. Hier finden regelmäßig Konzerte unterschiedlichster Stilrichtungen statt, bei denen wir versuchen,
                            den Eintrittspreis so günstig wie möglich zu halten, um möglichst vielen Menschen Zugang zu Musikveranstaltungen zu ermöglichen.
                        </p>
                        <p>
                            Gern kannst du auch selber ein Konzert veranstalten, dich ausprobieren und dich in der Orga-Crew einbringen.
                            In der Doro ist zudem eine P.A. sowie Backline-Equipment vorhanden, so dass du keine Soundanlage mieten musst.
                            Wir freuen uns auf deine Ideen.
                        </p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                        <img src="/img/Doro Veranstaltungsraum.webp" className="img-fluid rounded" alt="Doro Event 2" />
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                        <img src="/img/Doro außen.webp" className="img-fluid rounded" alt="Doro Event 1" />
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                        <img src="/img/Doro Veranstaltungsraum 2.webp" className="img-fluid rounded" alt="Doro Event 3" />
                    </div>

                    <div id='angebot2' className='col-sm-12 pt-5' style={{ textAlign: 'justify' }}>
                        <h3> Küche für Alle</h3>
                        <p>
                            Jeden Mittwoch um 18 Uhr findet im Infoladen La Bombonera die Küche für Alle statt.
                            Vor Ort erwarten euch kalte Getränke und eine warme vegane Mahlzeit auf Spendenbasis.
                            Die wöchentliche Küfa hat sich zudem als offener Treffpunkt in lockerer Atmosphäre etabliert und ist eine gute Möglichkeit, mit uns in Kontakt zu kommen.
                        </p>
                    </div>

                    <div id='angebot3' className='col-sm-12 py-5' style={{ textAlign: 'justify' }}>
                        <h3>Beratung</h3>
                        <p>
                            Im Bürgertreff Eckpunkt bieten wir während der Öffnungszeiten Beratungen für Opfer rechter Gewalt an und geben Hilfestellung bei bürokratischen Hürden oder Behördengängen für Menschen ohne ausreichende Deutschkenntnisse.
                            Weiter stehen wir mit vielen verschiedenen Beratungsstellen wie z.B. dem LSVD Sachsen, Sisters e.V. im Austausch.
                        </p>
                    </div>
                </div>
            </div>
        </div>

    )
};

export default AngeboteSeite;
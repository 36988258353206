import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import '../css/carousel.css'; // Import the CSS file

import config from '../config';

const CarouselComponent = () => {
  const [posts, setPosts] = useState([]);
  const placeholderImage = 'path/to/placeholder-image.jpg'; // Pfad zu deinem Placeholder-Bild

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  useEffect(() => {
    // Ersetze die URL durch den Pfad zu deinem PHP-Endpunkt
    fetch(`${config.apiUrl}/get-latest-posts-for-carousel.php`)
      .then(response => response.json())
      .then(data => setPosts(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div id='carousel-wrapper'>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={8000}
        keyBoardControl={true}
        customTransition="transform 0.5s ease-in-out"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {posts.length > 0 ? (
          posts.map((post, index) => (
            <div key={index}>
              <img
                className="d-block w-100"
                src={post.imagePath}
                alt={`Slide ${index + 1}`}
                style={{ objectFit: 'cover' }}
              />
              <div className="carousel-caption d-block">
                {/*<h4 className='mb-5'>{post.titel}</h4>*/}
                <a className="btn btn-default" href={`/post/${post.id}`}>
                  Erfahre mehr...
                </a>
              </div>
            </div>
          ))
        ) : (
          <div>
            <img
              className="d-block w-100"
              src={placeholderImage}
              alt="Placeholder"
              style={{ objectFit: 'cover' }}
            />
            <div className="carousel-caption d-block">
              <h5>Keine Beiträge gefunden</h5>
              <p>Bitte besuchen Sie uns später erneut.</p>
            </div>
          </div>
        )}
      </Carousel>
    </div>
  );
};

export default CarouselComponent;

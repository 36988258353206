import React, { useState, useEffect, useRef } from 'react';
import '../css/UploadFormularStyle.css';

import { Editor } from '@tinymce/tinymce-react';
import config from '../../../../config';

const UploadFormular = () => {
  const [editorContent, setEditorContent] = useState('');

  //Referenz für den TinyMCE-Editor
  const editorRef = useRef(null);

  //Loggen, wenn der Content geupdated wird
  const handleEditorChange = (content, editor) => {
    console.log('Content was updated:', content);
  };

  //Lädt Bilder auf den Server und gibt die URL zurück, damit sie beim src-Tag des Bildes eingefügt wird
  const handleImageUpload = (blobInfo) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());

      fetch(`${config.apiUrl}/UploadBild.php`, {
        method: 'POST',
        body: formData,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (!data || typeof data.url !== 'string') {
            throw new Error('Invalid response from server');
          }
          resolve(data.url);
        })
        .catch(error => {
          reject('Error uploading image: ' + error.message);
        });
    });
  };




  const handleSubmit = async (event) => {
    event.preventDefault();

    // Erstelle ein FormData-Objekt
    const formData = new FormData();

    // Füge Textdaten hinzu
    formData.append('titel', event.target.titel.value);
    formData.append('autor', event.target.autor.value);
    formData.append('datum', event.target.datum.value);
    formData.append('text', editorRef.current.getContent());
    formData.append('hashtags', event.target.hashtags.value);

    // Verwende fetch, um die Daten an dein PHP-Skript zu senden
    try {
      const response = await fetch(`${config.apiUrl}/UploadBeitrag.php`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();

      if (result.success) {
        // Erfolg
        alert(result.message);
        // Setzen Sie hier möglicherweise den Editor-Inhalt zurück und/oder leiten Sie um
        editorRef.current.setContent(''); // Setze den Editor-Inhalt zurück
      } else {
        // Fehler
        alert(result.message);
      }

    } catch (error) {
      alert('Fehler beim Senden des Beitrags: ' + error);
    }
  };




  return (
    <div className="container" style={{ paddingTop: '2rem' }}>
      <div className="row">
        <div className="col-sm-12">
          <form method="POST" onSubmit={handleSubmit}>
            <div class="mb-3">
              <label for="titel" class="form-label">Titel</label>
              <input type="text" class="form-control" id="titel" name="titel" required />
            </div>
            <div class="mb-3">
              <label for="autor" class="form-label">Autor</label>
              <input type="text" class="form-control" id="autor" name="autor" required />
            </div>
            <div class="mb-3">
              <label for="datum" class="form-label">Datum</label>
              <input type="datetime-local" class="form-control" id="datum" name="datum" required />
            </div>
            <div class="mb-3">
              <label for="text" class="form-label">Text</label>
              <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                apiKey='r8g8k68ilhh3yajasu3reneegxc5cuoelvjvyub7oan667l5'
                init={{
                  language: 'de',
                  height: 500,
                  plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align lineheight | link image media table | addcomment showcomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                  images_upload_url: 'no-op', // Dummy URL, actual upload handled by the custom handler
                  images_upload_handler: handleImageUpload, //Handelt imageUpload, dass die Bilder nicht als Base64-String gespeichert werden
                  tinycomments_mode: 'embedded',
                  tinycomments_author: 'Author name',
                  mergetags_list: [
                    { value: 'First.Name', title: 'First Name' },
                    { value: 'Email', title: 'Email' },
                  ],
                }}
                initialValue="Verfasse hier deinen Beitragsartikel!"
                onEditorChange={handleEditorChange}
              />
            </div>
            <div class="mb-3">
              <label for="hashtags" class="form-label">Hashtags</label>
              <input type="text" class="form-control" id="hashtags" name="hashtags" />
            </div>
            <button type="submit" class="btn btn-primary mb-5">Beitrag erstellen</button>
          </form>

        </div>
      </div>
    </div>
  );
}

export default UploadFormular;
import React, { useEffect, useRef } from 'react';
import '../css/animations.css'; // Importiere die CSS-Datei mit der Animation

const AnimatedHeading = ({ text }) => {
  const headingRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const letters = entry.target.querySelectorAll('.letter');
            letters.forEach((letter, index) => {
              letter.style.animationDelay = `${index * 0.1}s`;
              letter.classList.add('visible');
            });
            observer.unobserve(entry.target); // Stop observing after animation starts
          }
        });
      },
      { threshold: 0.1 } // Der Callback wird ausgelöst, wenn 10% des Elements sichtbar sind
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, [text]);

  return (
    <h1 ref={headingRef}>
      {text.split('').map((char, index) => (
        <span
          key={index}
          className="letter"
          style={char === ' ' ? { display: 'inline-block', width: '0.5em' } : {}}
        >
          {char}
        </span>
      ))}
    </h1>
  );
};

export default AnimatedHeading;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom'; // Zum Abrufen der Post-ID aus der URL und Navigieren
import DOMPurify from 'dompurify';
import { FaAngleLeft, FaAngleRight, FaList } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';
import '../../css/Aktuelles/PostStyle.css';
import config from '../../config';

const Post = () => {
  const navigate = useNavigate(); // useNavigate Hook
  const { postId } = useParams(); // Extrahiert die Post-ID aus der URL
  const [post, setPost] = useState(null);
  const [prevId, setPrevId] = useState(null);
  const [nextId, setNextId] = useState(null);

  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1); // Default-Wert: 1

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' }); // Beispiel: Maximal 768px breit gilt als Mobilgerät

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageParam = searchParams.get('page');
    const parsedPage = parseInt(pageParam, 10);
    if (!isNaN(parsedPage) && parsedPage > 0) {
      setCurrentPage(parsedPage);
    } else {
      setCurrentPage(1); // Fallback-Wert: 1, wenn der page-Parameter ungültig ist
    }
  }, [location.search]);


  useEffect(() => {
    fetch(`${config.apiUrl}/GetPostById.php?id=${postId}`)
      .then(response => response.json())
      .then(data => {
        setPost(data.post);
        setPrevId(data.prevId);
        setNextId(data.nextId);
      })
      .catch(error => console.error("Fehler beim Laden des Beitrags:", error));
  }, [postId]);

  //Anpassung des Datums
  const benutzerfreundlichesDatum = (datum) => {
    // Umwandlung in ein JavaScript-Datum
    const dateObject = new Date(datum);

    // Umwandlung in ein freundliches Format
    const friendlyDate = dateObject.toLocaleDateString('de-DE', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });

    return friendlyDate;
  }

  function Post({ content }) {
    // Entfernen von Backslashes vor Apostrophen im content-String
    const contentWithoutEscapedApostrophes = content.replace(/\\'/g, "'");

    // Dekodieren des angepassten Inhalts und Entfernen von Escape-Zeichen
    let decodedContent;
    try {
      // Hier wird der angepasste String dekodiert
      decodedContent = JSON.parse('"' + contentWithoutEscapedApostrophes + '"');
    } catch (error) {
      console.log("Fehler: ", error);
      decodedContent = ''; // Verwenden Sie einen leeren String als Fallback
    }

    const cleanContent = DOMPurify.sanitize(decodedContent);

    return (
      <div className="ck-content" dangerouslySetInnerHTML={{ __html: cleanContent }} />
    );
  }


  if (!post) {
    return (
      <div className="container">
        <div className="row text-center">
          <div className="col-sm-12">
            <h1 style={{ paddingTop: '10rem' }}>Lädt...</h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='post-wrapper'>
      <Helmet>
        <title>{post.titel}</title>
      </Helmet>
      {/* Inhalt deiner Seite */}
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1 className='text-center' style={{ paddingTop: '8rem' }}>{post.titel}</h1>
            <hr />
            <h2>{post.autor} - {`Verfasst am  ${benutzerfreundlichesDatum(post.datum)}`}</h2>

            <Post content={post.text} />

            <p className='text-muted mt-5'>{post.hashtags}</p>
            <hr />
          </div>
          {/* Desktop-Ansicht */}
          {!isMobile && (
            <div class="d-flex justify-content-between mb-5">
              <button class="btn btn-primary" disabled={!prevId} onClick={() => prevId && navigate(`/post/${prevId}`)}>
                Vorheriger Beitrag
              </button>
              <button class="btn btn-primary" onClick={() => navigate(`/aktuelles?page=${currentPage}`)}>
                Zur Übersicht
              </button>
              <button class="btn btn-primary" disabled={!nextId} onClick={() => nextId && navigate(`/post/${nextId}`)}>
                Nächster Beitrag
              </button>
            </div>
          )}

          {/* Mobile Ansicht */}
          {isMobile && (
            <div class="d-flex justify-content-between mb-5">
              <button class="btn btn-primary" disabled={!prevId} onClick={() => prevId && navigate(`/post/${prevId}`)}>
                <FaAngleLeft /> {/* Icon für vorherigen Beitrag */}
              </button>
              <button class="btn btn-primary" onClick={() => navigate(`/aktuelles/?page=${currentPage}`)}>
                <FaList /> {/* Icon für Übersicht */}
              </button>
              <button class="btn btn-primary" disabled={!nextId} onClick={() => nextId && navigate(`/post/${nextId}`)}>
                <FaAngleRight /> {/* Icon für nächsten Beitrag */}
              </button>
            </div>
          )}
        </div>
      </div>
    </div >

  );
};

export default Post;

import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import HighlightText from './components/HighlightText';

const VereinSeite = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div id="verein-wrapper" style={{ paddingTop: '8rem', minHeight: '100vh' }}>
            <Helmet>
                <title>Vereinsseite | Limbach für Alle</title>
            </Helmet>
            <div className="container">
                <div className="row text-center">
                    <div className="col-sm-12">
                        <h1>Unser Verein im Allgemeinen </h1>
                        <hr style={{ color: 'var(--primary-color)' }} />
                    </div>
                </div>
                <div className="row text-center pt-4">
                    <p style={{ textAlign: 'justify' }}>
                        Wir sind ein Zusammenschluss von verschiedenen Gruppen und Einzelpersonen, die das Ziel haben
                        uns in Limbach-Oberfrohna und darüber hinaus für eine offene und solidarische Gesellschaft
                        einzusetzen.
                    </p>
                    <p style={{ textAlign: 'justify' }}>
                        Durch die Orte der Demokratie wollen wir in Limbach-Oberfrohna Möglichkeiten
                        schaffen, die verschiedensten Menschen zusammenzubringen, uns kennenzulernen und
                        auszutauschen und gemeinsam neue Ideen und Projekte zu entwickeln und umzusetzen und so
                        gemeinsam Limbach für alle zu einem Ort machen. Wir freuen uns immer, über neue, interessierte
                        Menschen, die unsere Veranstaltungen besuchen und natürlich auch über Menschen, die Lust haben,
                        sich einzubringen.
                    </p>

                    <p style={{ textAlign: 'justify' }}>
                        Vielleicht habt ihr eine Projektidee, aber euch fehlen Räume, in denen ihr die
                        Veranstaltung umsetzen könnt oder ihr braucht andere Unterstützung, bei der wir euch helfen
                        können. Oder ihr habt einfach Lust, euch mit anderen auszutauschen, neue Leute kennenzulernen
                        und gemeinsam etwas umzusetzen.
                        <br />
                        <br />
                    </p>
                    <h3 style={{ textAlign: 'justify' }}>Wie kann man mit machen?</h3>
                    <p style={{ textAlign: 'justify' }}>
                        Wenn ihr Interesse oder Fragen habt, gerne mehr wissen oder mitmachen und euch einbringen
                        möchtet oder was auch immer, dann könnt ihr uns auf verschiedenen Wegen erreichen:


                        <ul className='pt-3'>
                            <li><HighlightText text="E-Mail: " /><a href="mailto:info@limbach-fuer-alle.de">info@limbach-fuer-alle.de</a></li>
                            <li><HighlightText text="Instagram: " /><a href="https://www.instagram.com/limbachfueralle" target="_blank">@limbachfueralle</a></li>
                            <li>... oder nutzt <Link to="/kontakt"><HighlightText text="hier" /></Link> unser Kontaktformular</li>
                        </ul>
                    </p>

                    <p style={{ textAlign: 'justify' }}>Vielen Dank an alle, die sich melden und uns unterstützen. Wir freuen uns auf euch!</p>
                </div>
            </div>
        </div>

    )
};

export default VereinSeite;
import React from 'react';
import { Navigate } from 'react-router-dom';

const isTokenExpired = (token) => {
    if (!token) {
        return true; // Wenn kein Token vorhanden ist, behandele es als abgelaufen
    }
    try {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const currentTime = Date.now() / 1000;
        return decodedToken.exp < currentTime;
    } catch (error) {
        console.error("Token decoding error:", error);
        return true; // Bei einem Fehler beim Dekodieren, behandele den Token als abgelaufen
    }
};

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');

    // Da `isTokenExpired` eine Nebenwirkung aufgrund des Zustands des Tokens ist, solltest du sie direkt in der Render-Logik verwenden,
    // um zu entscheiden, ob du renderst oder umleitest. `navigate` wird hier nicht benötigt.
    if (!token || isTokenExpired(token)) {
        localStorage.removeItem('token'); // Token entfernen
        return <Navigate to="/login" replace />;
    }

    return children; // Wenn das Token gültig ist, zeige die geschützten Kinder-Komponenten
};

export default ProtectedRoute;

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import config from '../../../config';
import './css/DeleteStyle.css';

const DeleteBeitraegePage = () => {
    const [beitraege, setBeitraege] = useState([]);
    const [selectedBeitraege, setSelectedBeitraege] = useState([]);

    useEffect(() => {
        // Funktion zum Abrufen der Beiträge
        const fetchBeitraege = async () => {
            const response = await fetch(`${config.apiUrl}/GetBeitraege2.php`);
            const data = await response.json();
            setBeitraege(data.beitraege);
        };

        fetchBeitraege();
    }, []);

    const handleCheckboxChange = (beitragId) => {
        const istAusgewaehlt = selectedBeitraege.includes(beitragId);
        if (istAusgewaehlt) {
            setSelectedBeitraege(selectedBeitraege.filter(id => id !== beitragId));
        } else {
            setSelectedBeitraege([...selectedBeitraege, beitragId]);
        }
    };

    const handleDelete = async () => {
        await fetch(`${config.apiUrl}/DeleteBeitraege2.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ beitraegeIds: selectedBeitraege }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Netzwerkantwort war nicht ok');
                }
                return response.json();
            })
            .then(data => {
                if (data.success) {
                    alert("Löschen der Beiträge war erfolgreich!");
                    // Entferne gelöschte Beiträge aus der lokalen Zustandsverwaltung, damit die UI aktualisiert wird
                    setBeitraege(beitraege.filter(beitrag => !selectedBeitraege.includes(beitrag.id)));
                    setSelectedBeitraege([]); // Leere Auswahl nach dem Löschen
                } else {
                    alert("Löschen der Beiträge war nicht erfolgreich!");
                }
            })
            .catch(error => {
                console.error('Fehler beim Verarbeiten der Antwort:', error);
            });
    };

    return (
        <div id='delete-wrapper'>
            <div className="container">
                <h2>Beiträge löschen</h2>
                <form onSubmit={(e) => { e.preventDefault(); handleDelete(); }}>
                    <div className="form-group">
                        {beitraege.map(beitrag => (
                            <div key={beitrag.id} className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`beitrag-${beitrag.id}`}
                                    checked={selectedBeitraege.includes(beitrag.id)}
                                    onChange={() => handleCheckboxChange(beitrag.id)}
                                />
                                <label className="form-check-label" htmlFor={`beitrag-${beitrag.id}`}>
                                    {beitrag.titel}
                                </label>
                            </div>
                        ))}
                    </div>
                    <button type="submit" className="btn btn-danger my-3">Ausgewählte Beiträge löschen</button>
                </form>
            </div>
        </div>
    );
};

export default DeleteBeitraegePage;

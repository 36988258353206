import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import HighlightText from './components/HighlightText';

const MitmachenSeite = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div id="mitmachen-wrapper" style={{ paddingTop: '8rem', minHeight: '100vh' }}>
            <Helmet>
                <title>Mitmachen | Limbach für Alle</title>
            </Helmet>
            <div className="container">
                <div className="row text-center">
                    <div className="col-sm-12">
                        <h1>Mitmachen und Unterstützen </h1>
                        <hr style={{ color: 'var(--primary-color)' }} />
                    </div>
                </div>
                <div className="row text-justify pt-4">
                    <p>
                        Limbach für Alle lebt von der Partizipation und wir freuen uns über neue Gesichter.
                        Kommt gern bei unseren Veranstaltungen oder Orten vorbei und sprecht mit uns. In unseren laufenden Projekten
                        gibt es immer etwas zu tun.
                    </p>
                    <p>
                        Außerdem sind wir immer offen für neue Ideen oder Projekte, die mit
                        unserem Selbstverständnis übereinstimmen. Selbst wenn ihr noch keine Erfahrung mit der
                        Umsetzung eures Vorhabens habt, können wir gern zusammen euer Konzept konkretisieren und
                        gemeinsam das Projekt realisieren. Selbstverständlich freuen wir uns auch über eine
                        Fördermitgliedschaft oder Spenden, die uns bei der Fortsetzung unserer Arbeit Sicherheit geben.
                    </p>
                </div>
            </div>
        </div>

    )
};

export default MitmachenSeite;
import React from 'react';

const DeleteFormularHeader = () => {
    return(
        <div className="container">
            <div className="row text-center">
                <div className="col-sm-12">
                    <h1 style={{paddingTop: '8rem'}}>Löschen von Beiträgen aus der Datenbank!</h1>
                    <hr />
                </div>
            </div>
        </div>
    );
}

export default DeleteFormularHeader;
import React from 'react';

const PostsHeader = () => {
    return(
        <div className="container">
            <div className="row text-center">
                <div className="col-sm-12">
                    <h1 style={{paddingTop: '8rem'}}>Aktuelle Beiträge</h1>
                    <hr style={{color: 'var(--primary-color)'}}/>
                </div>
            </div>
        </div>
    );
}

export default PostsHeader;
import React, { useState } from 'react';
import '../css/KontaktFormularStyle.css';

import config from '../config';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        Absender: '',
        Betreff: 'Kontaktaufnahme',
        Email: '',
        Nachricht: ''
    });

    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${config.apiUrl}/send_email_new.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            if (result.success) {
                setMessage('Ihre Nachricht wurde erfolgreich gesendet.');
            } else {
                setMessage('Es gab ein Problem beim Senden Ihrer Nachricht: ' + result.error);
            }
        } catch (error) {
            setMessage('Es gab ein Problem beim Senden Ihrer Nachricht: ' + error.message);
        }
    };

    return (
        <div id='kontaktformular-wrapper'>
            <div className="container">
                <div className="row text-center">
                    <div className="col-sm-12">
                        <h1>Du möchtest mit uns Kontakt aufnehmen?</h1>
                        <hr style={{ color: 'var(--primary-color)' }} />
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col-sm-4">
                        <h3>Kontakt</h3>
                        <p>Kontaktiere uns gern über das Formular!</p>
                        <p>Falls Du eine andere Variante bevorzugst, sind am Ende der Webseite weitere Kontaktmöglichkeiten hinterlegt!</p>
                    </div>
                    <div className="col-sm-8">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="inputName">Name<span className="required">*</span></label>
                                <input
                                    name="Absender"
                                    type="text"
                                    className="form-control"
                                    id="inputName"
                                    placeholder="Verrate mir deinen Namen"
                                    value={formData.Absender}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Betreff">Betreff<span className="required">*</span></label>
                                <select
                                    name="Betreff"
                                    className="form-control"
                                    id="Betreff"
                                    value={formData.Betreff}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="Kontaktaufnahme">Kontaktaufnahme</option>
                                    <option value="Bewerbung">Bewerbung</option>
                                    <option value="Spende">Spende</option>
                                    <option value="Sonstiges">Sonstiges</option>
                                </select>
                                <small id="betreffHilfe" className="form-text text-muted">Bitte wähle eine entsprechende Option aus.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputMail">E-Mail-Adresse<span className="required">*</span></label>
                                <input
                                    name="Email"
                                    type="email"
                                    className="form-control"
                                    id="inputMail"
                                    placeholder="E-Mail-Adresse eingeben"
                                    value={formData.Email}
                                    onChange={handleChange}
                                    required
                                />
                                <small id="emailHilfe" className="form-text text-muted">Es werden keine Daten an Dritte weitergegeben.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="textareaNachricht">Deine Nachricht<span className="required">*</span></label>
                                <textarea
                                    name="Nachricht"
                                    className="form-control"
                                    id="textareaNachricht"
                                    rows="5"
                                    value={formData.Nachricht}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                            </div>
                            <button className="btn btn-dark mt-4" type="submit">Senden</button>
                        </form>
                        {message && <p id="message-submit">{message}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;

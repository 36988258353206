import React from 'react';
import { Helmet } from 'react-helmet';

import UploadFormular from './Upload/components/UploadFormular.jsx';
import UploadFormularHeader from './Upload/components/UploadFormularHeader.jsx';

import 'bootstrap/dist/css/bootstrap.min.css';
const Upload = () => {
    return (
        <div>
            <Helmet>
                <title>Upload-Bereich</title>
            </Helmet>
            <UploadFormularHeader />
            <UploadFormular />
        </div>

    );
}

export default Upload;
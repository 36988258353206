import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AOS from 'aos';


import AnimatedHeading from './components/AnimatedHeading';
import HighlightText from './components/HighlightText';
import ArrowAnimation from './components/ArrowAnimation';

import CarouselComponent from './components/Carousel';
import Standorte from './components/StandorteIndex';

import './css/IndexSeiteStyle.css';
import './css/font-sizes.css';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const IndexSeite = () => {

    useEffect(() => {
        AOS.init({
            duration: 1200, // Optionale Konfiguration
            delay: 200
        });
    }, []);

    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div id="index-wrapper">
            <Helmet>
                <title>Startseite | Limbach für Alle</title>
            </Helmet>
            <div className="banner container-fluid pb-5" >
            <ArrowAnimation />
            </div>

            <div className="container py-5 my-5" id="index-welcome">
                <div className="row text-center">
                    <div className="col-sm-12">
                        <h1 data-aos-offset="0" data-aos="fade-down"> LIMBACH-OBERFROHNA <span style={{ color: 'var(--primary-color)' }}>FÜR</span> ALLE!</h1>
                        <p data-aos="fade-up" className='pt-5' style={{ textAlign: 'justify' }}>
                            <br />Wir sind das Bündnis "Limbach für Alle", ein zivilcouragierter Zusammenschluss aus Gruppen, Organisationen und Einzelpersonen.
                            <HighlightText text=" Gemeinsam" /> setzen wir uns gegen menschenverachtende, intolerante und diskriminierende Ideologien ein. 
                            Unsere Arbeit basiert auf den Prinzipien der <HighlightText text="Solidarität, Gerechtigkeit und Gleichheit" />. 
                            Wir kämpfen aktiv <HighlightText text="gegen"/> Rassismus, Sexismus, Homophobie und alle Formen von <HighlightText text="Diskriminierung"/>.
                        </p>
                        <p data-aos="fade-up" style={{ textAlign: 'justify' }}>
                        <br />Unser Bündnis ist bestrebt, eine progressive, offene und <HighlightText text="gerechte Gesellschaft zu fördern"/>. Wir organisieren Aufklärungsveranstaltungen, Demonstrationen und
                            Workshops, um ein Bewusstsein für soziale Ungerechtigkeiten zu schaffen und um Veränderung zu bewirken. Durch unsere Bildungs- und Kulturarbeit möchten wir
                            die <HighlightText text="Vielfalt"/> und <HighlightText text="Inklusion"/> in unserer Gemeinschaft stärken.
                        </p>
                        <p data-aos="fade-up" style={{ textAlign: 'justify' }}>
                        <br />Außerdem unterstützen wir unsere Bündnispartner in ihrer progressiven Arbeit zu verschiedenen Themen wie Klimagerechtigkeit, soziale Sicherheit, Arbeitnehmerrechte und Antifaschismus.
                            Unser Ziel ist es, gemeinsam mit unseren Partner eine <HighlightText text="nachhaltige"/> und <HighlightText text="solidarische Gesellschaft"/> aufzubauen, in der alle Menschen <HighlightText text="gleiche"/> Chancen und Rechte haben.
                        </p>
                        <p data-aos="fade-up" style={{ textAlign: 'justify' }}> 
                        <br />Wir glauben fest daran, dass <HighlightText text="Veränderung"/> durch gemeinschaftliches Handeln und gegenseitige Unterstützung <HighlightText text="möglich"/> ist. 
                        Deshalb laden wir alle ein, sich uns anzuschließen, aktiv zu werden und Teil einer Bewegung zu sein, die für eine gerechtere und menschlichere Welt kämpft.
                        </p>
                    </div>

                    <div className='col-sm-12 pt-5'>
                        <AnimatedHeading text="AKTUELLES" />
                        <hr style={{ color: 'var(--primary-color)' }} />
                    </div>

                    <div className='col-sm-12 pt-5'>
                        <CarouselComponent />
                        <h3 className='pt-5' data-aos="fade-up">Du möchtest <Link to="/aktuelles"><HighlightText text="weitere Beiträge lesen"/></Link> oder einfach nur durch unser Beitragsarchiv stöbern?</h3>
                        <p className='pt-5' data-aos="fade-up" style={{ textAlign: 'justify' }}>
                            
                        Dann zögere nicht und schaue auf unserer <HighlightText text="Aktuelles"/>-Seite vorbei!
                            Hier findest du eine Fülle von informativen Artikeln, <HighlightText text="spannenden"/> Berichten und aktuellen Nachrichten zu den vielfältigen Aktivitäten und Initiativen
                            unseres Vereins. Egal ob es um politische Diskussionen, <HighlightText text="gesellschaftliche Projekte"/> oder kulturelle Veranstaltungen geht – unser Archiv bietet dir einen umfassenden Einblick in unser <HighlightText text="Engagement"/> und unsere gemeinsamen <HighlightText text="Ziele"/>.
                        </p>

                        <p data-aos="fade-up" style={{ textAlign: 'justify' }}>
                        <br />Unsere Beiträge sind <HighlightText text="sorgfältig"/> zusammengestellt und bieten dir nicht nur interessante Einblicke, sondern auch <HighlightText text="wertvolle Informationen"/> und Anregungen.
                            Lass dich inspirieren und entdecke die Vielfalt unseres Vereinslebens.
                        </p>
                        <p data-aos="fade-up" style={{ textAlign: 'justify' }}>
                        <br />Wir freuen uns darauf, unsere Erlebnisse und Erkenntnisse mit dir zu teilen und gemeinsam neue Wege zu gehen.
                            Besuche <HighlightText text="regelmäßig"/> unsere Webseite, um nichts zu verpassen und stets <HighlightText text="auf dem Laufenden zu bleiben"/>.
                        </p>

                        <p data-aos="fade-up" style={{ textAlign: 'justify' }}>
                            <br />Dein <HighlightText text="Interesse"/> und deine <HighlightText text="Teilnahme"/> sind uns wichtig, denn gemeinsam können wir viel bewegen!
                        </p>
                    </div>

                    <div className='col-sm-12 pt-5'>
                        <AnimatedHeading text="UNSERE STANDORTE" />
                        <hr style={{ color: 'var(--primary-color)' }} />
                    </div>

                    <div className='col-sm-12 pt-5'>
                        <Standorte />
                    </div>

                    <div className='col-sm-12 pt-5'>
                        <AnimatedHeading text="UNSERE UNTERSTÜTZER" />
                        <hr style={{ color: 'var(--primary-color)' }} />
                        <img className='mt-5' id='banner-foerdermittel' src="/03_Abbildungsdatei_Orte_der_Demokratie_1-scaled.jpg" alt="Orte der Demokratie" />
                    </div>
                </div>
            </div>
        </div>



    )
};

export default IndexSeite;
import React from 'react';
import HighlightText from './HighlightText';


const Standorte = () => {
    const standorte = [
        {
            id: 'standort1',
            title: 'Infoladen „La Bombonera“',
            description: [
                <p key="1">Das <HighlightText text="La Bombonera" /> ist das Vereinsdomizil der Bildungsvereinigung auf der Sachsenstraße in Limbach-Oberfrohna. Es sind mehrere von uns selbst hergerichtete und in Eigenregie betriebene Räume, die als Treffpunkt und Veranstaltungsort dienen.</p>,
                <p key="2"><HighlightText text="Jeden Mittwoch" /> findet im Infoladen La Bombonera auf der Sachsenstraße 26, 09212 Limbach-Oberfrohna <HighlightText text="ab 18 Uhr die KüfA (Küche für Alle) statt" />.</p>,
                <p key="3">Das ist ein offener Treffpunkt, bei dem es ein warmes Essen gegen eine Spende (jede*r gibt, was er*sie hat) gibt. Außerdem gibt es Getränke, Musik und Räume, in denen man sich treffen und quatschen kann.</p>,
                <p key="4">Außerdem gibt es in unregelmäßigen Abständen <HighlightText text="Vorträge, Workshops, Filmabende" /> usw. zu verschiedenen Themen, die wir aktuell und wichtig finden. Infos dazu findet ihr immer hier oder bei Instagram und Facebook.</p>,
                <p key="5">Und ihr könnt euch hier auch mit aktuellen Flyern, Postern und Infomaterial eindecken.</p>,
            ],
            imageUrl: 'img/Infoladen La Bombonera.webp',
        },
        {
            id: 'standort2',
            title: 'Alternatives Zentrum „Doro 40“',
            description: [
                <p key="1">Die <HighlightText text="Doro 40" /> ist eine Doppelhaushälfte auf der Dorotheenstraße 40 in Limbach-Oberfrohna und konnte in den letzten Jahren zu einem selbstverwalteten, <HighlightText text="alternativen Zentrum" /> ausgebaut werden, in der seit der Eröffnung im August 2023 regelmäßig verschiedene Veranstaltungen stattfinden.</p>,
                <p key="2">Vor einigen Jahren begann eine Gruppe von jungen Menschen, die Räume der Dorotheenstraße zu nutzen und die Verantwortung für das Haus und seinen Ausbau zu übernehmen.</p>,
                <p key="3"> Selbstverwaltet und neben Schule, Ausbildung oder Studium entstand hier ein Ort für alle, die ein Projekt auf die Beine stellen wollen und die sich klar für eine offene Gesellschaft einsetzen, ein Ort, an dem <HighlightText text="Konzerte, Partys, Filmabende, Vorträge, Workshops" /> und alles, auf was man sonst noch Lust hat, stattfinden können.</p>,
                <p key="4">Auch wenn das Erdgeschoss der <HighlightText text="Doro 40" /> jetzt eine öffentliche Veranstaltungsfläche ist, gibt es immer noch genug zu tun. Wir versuchen dabei so viele Baumaßnahmen wie möglich selbst auszuführen und sind immer über <HighlightText text="helfende Hände" /> froh, wer sich hier gerne einbringen und auch eigene Vorstellungen zur Gestaltung des Projektes einbringen will, kann sich gerne bei uns melden.</p>,
                <p key="5">Und trotz dem großen Eigenanteil und der vielen Zeit und Arbeit, die wir in den Ausbau stecken, kostet so ein Bau natürlich Geld und braucht <HighlightText text="viele engagierte Menschen" /> – wer uns und unsere Idee also <HighlightText text="unterstützen" /> möchte, kann das natürlich gerne tun und hilft uns damit, hier in Limbach-Oberfrohna ein selbstverwaltetes, alternatives Jugendzentrum weiter auszubauen und schließlich zu erhalten.</p>,
            ],
            imageUrl: '/img/Doro außen.webp',
        },
        {
            id: 'standort3',
            title: 'Gemeinschaftsgarten',
            description: [
                <p key="1">Möchtest du <HighlightText text="aktiv werden" /> und hast du Interesse daran, gemeinsam mit anderen Natur zu erleben und dich für <HighlightText text="nachhaltige" /> Lebensmittelproduktion einzusetzen?</p>,
                <p key="2">Dann bist du im <HighlightText text="Garten für ALLE" /> genau richtig! Hier können wir uns zukünftig zum gemeinschaftlichen Gärtnern treffen.</p>,
                <p key="3">Der Zugang zum Garten wird allen Bürger*innen aus Limbach – O. und Umgebung offenstehen. Du kannst mit anderen Menschen gemeinsam <HighlightText text="Pflanzen anbauen" />, Unkraut jäten, <HighlightText text="ernten" /> oder andere Aktivitäten rund um die Pflege und Bewirtschaftung des Gartens durchführen.</p>,
                <p key="4">Zurzeit wählen wir ein passendes Grundstück aus und dann kann es los gehen. Willst du dich von <HighlightText text="Anfang an engagieren" /> und bei der Gestaltung des Projektes einbringen, dann <HighlightText text="melde dich" /> jetzt einfach schon bei uns.</p>,
            ],
            imageUrl: '/img/Doro Garten.webp',
        },
        {
            id: 'standort4',
            title: 'Bürgertreff „Eckpunkt“',
            description: [
                <p key="1">Auf der Querstraße 6 in Limbach-Oberfrohna findet ihr unseren <HighlightText text="„Eckpunkt“" />, den <HighlightText text="Mittelpunkt" /> des Projektes Limbach für ALLE. Die neu geschaffene Begegnungsstätte zwischen den bestehenden Orten ist die erste Anlaufstelle für alle Menschen aus Limbach-Oberfrohna und der Umgebung und wird ein vielfältiges Programm bieten.</p>,
                <p key="2">Neben regelmäßigen Öffnungszeiten für Anliegen der Bürger*innen werden auch <HighlightText text="Beratungen" /> zu verschiedenen Themen wie <HighlightText text="Rassismus, Sexismus" /> sowie Hilfestellung bei alltäglichen Problemen angeboten. Bildungspolitische Veranstaltungen sowie niedrigschwellige Angebote und informelle Treffen bei Kaffee und Kuchen ermöglichen den Austausch.</p>,
                <p key="3">Wir möchten hier aber gar <HighlightText text="kein starres Angebot" /> vorgeben, sondern freuen uns auf viele Interessierte, die mit uns gemeinsam diese Begegnungsstätte gestalten und mit Leben füllen.</p>,
            ],
            imageUrl: "https://imageplaceholder.net/600x600?text=21.07.2024",
        },
    ];

    return (
        <div className="container my-5">
            {standorte.map((standort) => (
                <div id={standort.id} key={standort.id} className="card mb-5">
                    <div className="row g-0">
                        <div className="col-md-4" style={{ position: 'relative' }}>
                            <img 
                                src={standort.imageUrl} 
                                className="img-fluid rounded-start" 
                                alt={standort.title} 
                                style={{ 
                                    width: '100%', 
                                    height: '100%', 
                                    objectFit: 'cover', 
                                    objectPosition: 'center', 
                                }}
                            />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h4 className="card-title">{standort.title}</h4>
                                <hr style={{ color: 'var(--primary-color)' }} />
                                {standort.description}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Standorte;

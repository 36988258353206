import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { motion } from 'framer-motion';
import HighlightText from './HighlightText';


import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import '../css/StandorteIndexStyle.css';

AOS.init();

const Standorte = () => {

  const scrollWithOffset = (el) => {
    const yOffset = -8 * 16; // 8rem in Pixel umrechnen (1rem = 16px)
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const standorte = [
    {
      name: "Infoladen 'La Bombonera'",
      description: "Das 'La Bombonera' ist das Vereinsdomizil der Bildungsvereinigung auf der Sachsenstraße in Limbach-Oberfrohna",
      details: "Es sind mehrere von uns selbst hergerichtete und in Eigenregie betriebene Räume, die als Treffpunkt und Veranstaltungsort dienen. Du möchtest mehr erfahren?",
      clickme: "Dann drück auf meine Karte!",
      imageUrl: "img/Infoladen La Bombonera.webp",
      delay: 0
    },
    {
      name: "Alternatives Zentrum 'Doro 40'",
      description: "Die 'Doro 40' ist eine Doppelhaushälfte auf der Dorotheenstraße 40 in Limbach-Oberfrohna.",
      details: "In den letzten Jahren konnte es zu einem selbstverwalteten, alternativen Zentrum ausgebaut werden, in der seit der Eröffnung im August 2023 regelmäßig verschiedene Veranstaltungen stattfinden. Du möchtest mehr erfahren?",
      clickme: "Dann drück auf meine Karte!",
      imageUrl: "/img/Doro Graffiti.webp",
      delay: 200
    },
    {
      name: "Gemeinschaftsgarten",
      description: "Möchtest du aktiv werden und hast du Interesse daran, gemeinsam mit anderen Natur zu erleben und dich für nachhaltige Lebensmittelproduktion einzusetzen?",
      details: "Dann bist du im Garten für ALLE genau richtig! Hier können wir uns zukünftig zum gemeinschaftlichen Gärtnern treffen. Du möchtest mehr erfahren?",
      clickme: "Dann drück auf meine Karte!",
      imageUrl: "/img/Doro Garten.webp",
      delay: 400
    },
    {
      name: "Bürgertreff 'Eckpunkt'",
      description: "Auf der Querstraße 6 in Limbach-Oberfrohna findet ihr unseren „Eckpunkt“, der Mittelpunkt des Projektes Limbach für ALLE.",
      details: "Die neu geschaffene Begegnungsstätte zwischen den bestehenden Orten ist die erste Anlaufstelle für alle Menschen aus Limbach-Oberfrohna und der Umgebung und wird ein vielfältiges Programm bieten.",
      clickme: "Dann drück auf meine Karte!",
      imageUrl: "https://imageplaceholder.net/1200x400?text=21.07.2024",
      delay: 400
    }
  ];

  return (
    <div id='standorte-wrapper'>
      <div className="container my-5 standort">
        <div className="row">
          {standorte.map((standort, index) => (
            <div
              key={index}
              className="col-lg-6 mb-5"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay={standort.delay}
            >
              <HashLink to={`/standorte#standort${index + 1}`} scroll={el => scrollWithOffset(el)}>
                <motion.div className="card" style={{ cursor: 'pointer' }}
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.9 }}>
                  <img
                    style={{ objectFit: 'cover', minHeight: '200px', minWidth: '100%' }}
                    src={standort.imageUrl}
                    className="card-img-top"
                    alt={`Bild des Standorts ${standort.name}`}
                  />
                  <div className="card-body">
                    <h4 className="card-title">{standort.name}</h4>
                    <hr className='pb-3' />
                    <p className="card-text">{standort.description}</p>
                    <p className="card-text">{standort.details}</p>
                    <p className="card-text"><HighlightText text={standort.clickme} /></p>
                  </div>
                </motion.div>
              </HashLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Standorte;

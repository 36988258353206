import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import KontaktFormular from './components/KontaktFormular';

const KontaktSeite = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <div id="WrapperKontakt" style={{minHeight: '100vh'}}>
            <Helmet>
                <title>Kontakt | Limbach für Alle</title>
            </Helmet>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <KontaktFormular />
                    </div>
                </div>
            </div>
        </div>

    )
};

export default KontaktSeite;
import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import HighlightText from './components/HighlightText';
import PaypalSpende from './components/PaypalSpende';

const SpendeSeite = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div id="spende-wrapper" style={{ paddingTop: '8rem', minHeight: '100vh' }}>
            <Helmet>
                <title>Spenden | Limbach für Alle</title>
            </Helmet>
            <div className="container">
                <div className="row text-center">
                    <div className="col-sm-12">
                        <h1>Ihre <HighlightText text="Spende" /> macht den Unterschied! </h1>
                        <hr style={{ color: 'var(--primary-color)' }} />
                    </div>
                </div>

                <div className="row mt-5" style={{ textAlign: 'justify' }}>
                    <div className="col-sm-12">
                        <p>Mit eurer Hilfe können wir weiterhin unsere vielfältigen Projekte und Initiativen zum Wohle der Gemeinschaft vorantreiben.
                            Egal, ob es um <HighlightText text="Bildungsangebote" />, kulturelle Veranstaltungen oder <HighlightText text="soziale Unterstützung" /> geht – jede Spende hilft uns, einen positiven Einfluss auf unser Umfeld zu nehmen.
                        </p>
                        <p>Eure Großzügigkeit ermöglicht es uns, unsere Vision einer lebendigen und starken Gemeinschaft zu verwirklichen. Gemeinsam können wir mehr erreichen!
                            Spendet jetzt und seid <HighlightText text="Teil unserer Mission" />.
                        </p>
                    </div>
                    {/* 
                    <div className="row mt-5" style={{ textAlign: 'justify' }}>
                        <div className="col-sm-12">
                            <p>Es gibt verschiedene Möglichkeiten, wie Sie uns unterstützen können. Wählen Sie die für Sie bequemste Methode:</p>
                            <ul>
                                <li><HighlightText text="PayPal:" /> Nutzen Sie den sicheren und bequemen PayPal-Spendenbutton unten.</li>
                                <li><HighlightText text="Banküberweisung:" /> Überweisen Sie direkt auf unser Konto. Die Kontodaten finden Sie unten. (noch in Arbeit)</li>
                                <li><HighlightText text="Patreon:" /> Werden Sie ein regelmäßiger Unterstützer über unsere Patreon-Seite. (noch in Arbeit)</li>
                            </ul>
                            <p>Jede Spende, egal wie groß oder klein, trägt dazu bei, unsere Mission zu unterstützen und unsere Gemeinschaft zu stärken. Vielen Dank für Ihre Großzügigkeit!</p>
                        </div>
                    </div>
                    <PaypalSpende />
                    */}

                    <div className="row" style={{ textAlign: 'justify' }}>
                        <div className="col-sm-12">
                            <p>Auch wenn Engagement und Gemeinschaft nicht mit Geld aufzuwiegen ist, macht es doch vieles einfacher.
                                <HighlightText text="Jede Spende" /> hilft, unsere Projekte weiter voranzutreiben und gibt uns Sicherheit bei der Planung.
                                Selbstverständlich können wir auch eine <HighlightText text="Spendenquittung" /> erstellen.
                            </p>
                            <p className='pt-5'>Überweisen Sie direkt auf unser Konto: </p>
                            <p>
                                Soziale und politische Bildungsvereinigung Limbach-Oberfrohna e.V. <br /> IBAN: DE54 8705 0000 3514 0165 09 <br />BIC: CHEKDE81XXX
                            </p>

                            <p className='pb-5'>Herzlichen Dank an alle Unterstützer!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};

export default SpendeSeite;
import { useEffect } from 'react';
import Posts from "./components/Aktuelles/Posts";
import PostsHeader from "./components/Aktuelles/PostsHeader";
import { Helmet } from 'react-helmet';

const AktuellesSeite = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="WrapperAktuellesSeite">
      <Helmet>
        <title>Aktuelles | Limbach für Alle</title>
      </Helmet>
      <PostsHeader />
      <Posts />
    </div>

  );
}

export default AktuellesSeite;
import React from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';

import DeleteFormular from './Delete/components/DeleteFormular.jsx';
import DeleteFormularHeader from './Delete/components/DeleteFormularHeader.jsx';
import DeleteBeitraegePage from './Delete/Delete.jsx';

const Delete = () => {
    return (
        <div id='WrapperDelete'>
            <Helmet>
                <title>Beitragslöschung</title>
            </Helmet>
            <DeleteFormularHeader />
            {/*<DeleteFormular />*/}
            <DeleteBeitraegePage />
        </div>

    );
}

export default Delete;
import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import config from '../../../config';
import './css/UploadEventStyle.css';

const UploadEvent = () => {
    const [title, setTitle] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [description, setDescription] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch(`${config.apiUrl}/add-event.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ title, start, end, desc: description }),
        });

        if (response.ok) {
            // Handle success (e.g., clear the form, show a success message, etc.)
            setTitle('');
            setStart('');
            setEnd('');
            setDescription('');
            alert('Event hinzugefügt!');
        } else {
            // Handle error
            alert('Fehler beim Hinzufügen des Events.');
        }
    };

    return (
        <div id='upload-event-wrapper'>
            <div className="container">
                <h2>Event hinzufügen</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="title">Titel:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="start">Start:</label>
                        <input
                            type="datetime-local"
                            className="form-control"
                            id="start"
                            value={start}
                            onChange={(e) => setStart(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="end">Ende:</label>
                        <input
                            type="datetime-local"
                            className="form-control"
                            id="end"
                            value={end}
                            onChange={(e) => setEnd(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Beschreibung:</label>
                        <Editor
                            apiKey="r8g8k68ilhh3yajasu3reneegxc5cuoelvjvyub7oan667l5" // Ersetze durch deinen TinyMCE-API-Schlüssel
                            value={description}
                            init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help'
                            }}
                            onEditorChange={(content) => setDescription(content)}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary my-3">Event hinzufügen</button>
                </form>
            </div>
        </div>
    );
};

export default UploadEvent;

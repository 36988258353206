import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchForm = () => {
    const [query, setQuery] = useState('');
    const navigate = useNavigate();

    const handleSearch = (e) => {
        e.preventDefault();
        if (query.trim().length >= 4) {
            navigate(`/search?query=${query}`);
        } else {
            alert('Bitte geben Sie mindestens 4 Buchstaben ein.');
        }
    };

    return (
        <form onSubmit={handleSearch} className="d-flex">
            <input
                className="form-control me-2 mx-4"
                type="search"
                placeholder="Beiträge durchsuchen..."
                aria-label="Beiträge durchsuchen..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
            />
        </form>
    );
};

export default SearchForm;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

import '../css/SuchergebnisseStyle.css';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Suchergebnisse = () => {
    const query = useQuery().get('query');
    const [results, setResults] = useState([]);

    useEffect(() => {
        if (query) {
            axios.get(`${config.apiUrl}/suchen.php?query=${query}`)
                .then(response => {
                    setResults(response.data);
                })
                .catch(error => {
                    console.error('Error fetching search results', error);
                });
        }
    }, [query]);

    return (
        <div id='suchergebnisse-wrapper' className="container" style={{ minHeight: '100vh', paddingTop: '8rem' }}>
            <h1 className="mb-4 text-center">Suchergebnisse für "{query}"</h1>
            <hr style={{ color: 'var(--primary-color)' }} />
            <div className="row">
                {results.map((result) => (
                    <div key={result.id} className="col-md-6 mb-4">
                        <div className="card h-100">
                            <div className="card-body">
                                <h3 className="card-title">{result.title}</h3>
                                <hr style={{ color: 'var(--primary-color)' }} />
                                <p className="card-text" dangerouslySetInnerHTML={{ __html: result.excerpt }} style={{textAlign: 'justify'}}></p>
                                <a href={`/post/${result.id}`} className="btn btn-primary">Mehr Lesen</a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Suchergebnisse;

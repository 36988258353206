import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import config from '../../../config';
import './css/DeleteEventStyle.css';

const DeleteEvent = () => {
    const [events, setEvents] = useState([]);
    const [selectedEventId, setSelectedEventId] = useState('');

    useEffect(() => {
        // Fetch events from your API
        fetch(`${config.apiUrl}/get-events`)
            .then(response => response.json())
            .then(data => setEvents(data));
    }, []);

    const handleDelete = async (event) => {
        event.preventDefault();

        if (selectedEventId) {
            const response = await fetch(`${config.apiUrl}/delete-event?id=${selectedEventId}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                setEvents(events.filter(event => event.id !== selectedEventId));
                setSelectedEventId('');
                alert('Event gelöscht!');
            } else {
                alert('Fehler beim Löschen des Events.');
            }
        } else {
            alert('Bitte wählen Sie ein Event zum Löschen aus.');
        }
    };

    return (
        <div id='delete-event-wrapper'>
            <div className="container">
                <h2>Event löschen</h2>
                <form onSubmit={handleDelete}>
                    <div className="form-group">
                        <label htmlFor="eventSelect"><p>Event auswählen:</p></label>
                        <select
                            className="form-control"
                            id="eventSelect"
                            value={selectedEventId}
                            onChange={(e) => setSelectedEventId(e.target.value)}
                            required
                        >
                            <option value="">Wählen Sie ein Event</option>
                            {events.map(event => (
                                <option key={event.id} value={event.id}>
                                    {event.title} ({new Date(event.start).toLocaleString()} - {new Date(event.end).toLocaleString()})
                                </option>
                            ))}
                        </select>
                    </div>
                    <button type="submit" className="btn btn-danger my-5">Event löschen</button>
                </form>
            </div>
        </div>
    );
};

export default DeleteEvent;

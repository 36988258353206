import React from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

const Login = () => {
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = {
            username: event.target.username.value,
            password: event.target.password.value,
        };

        try {
            const response = await fetch(`${config.apiUrl}/login_v2.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error(`Serverfehler: ${response.status}`);
            }

            const data = await response.json();
            try {
                if (data.success) {
                    localStorage.setItem('token', data.token);
                    navigate('/admin');
                } else {
                    alert(data.message);
                }
            } catch (jsonParseError) {
                console.error('Fehler beim Parsen der JSON-Antwort:', jsonParseError);
                // Hier könntest du eine spezifischere Fehlermeldung oder Behandlung hinzufügen
            }
        } catch (error) {
            console.error('Login fehlgeschlagen:', error);
            alert('Login fehlgeschlagen, bitte versuche es später erneut.');
        }
    };

    return (
        <div className="bg-light" style={{ minHeight: '100vh' }}>
            <div className="container" id="login">
                <div className="row text-center" style={{ paddingTop: '8rem' }}>
                    <div className="col-sm-12">
                        <h1>Login zum Admin-Bereich</h1>
                        <hr />
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col-sm-12">
                        <form id="LoginFormular" method="post" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="Benutzername">Benutzername</label>
                                <input name="username" type="text" className="form-control mt-2" id="Benutzername" placeholder="Benutzername..." />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="Passwort">Passwort</label>
                                <input name="password" type="password" className="form-control mt-2" id="Passwort" placeholder="Passwort..." />
                            </div>
                            <button className="btn btn-dark mt-3" type="submit">Anmelden</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
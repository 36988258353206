import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AdminPanel = () => {

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');

        navigate('/login');
    };


    return (
        <div id='admin-wrapper'>
            <Helmet>
                <title>Admin Panel | Limbach für Alle</title>
            </Helmet>
            <div className="container" style={{ paddingTop: '10rem', minHeight: '100vh' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <h1><Link to="/admin/upload">--{'>'} Beitrag hochladen für die Seite "Aktuelles" </Link></h1>
                    </div>

                    <div className="col-sm-12">
                        <h1><Link to="/admin/delete">--{'>'} Beitrag löschen </Link></h1>
                    </div>

                    <div className="col-sm-12">
                        <h1><Link to="/admin/upload-event">--{'>'} Veranstaltung hinzufügen</Link></h1>
                    </div>
                    
                    <div className="col-sm-12">
                        <h1><Link to="/admin/delete-event">--{'>'} Veranstaltung löschen</Link></h1>
                    </div>
                    
                </div>
                <div className="row text-center">
                    <div className="col-sm-12">
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminPanel;
import React from 'react';
import PropTypes from 'prop-types';
import '../css/HighlightText.css'; // Importiere die CSS-Datei

const HighlightText = ({ text }) => {
  return (
    <span className="highlight-text">
      {text}
    </span>
  );
};

HighlightText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default HighlightText;

import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import SearchForm from './SearchForm';

import logo from '../img/02_LOGO_Limbach_für_alle_schwarz_kopie.png';
import '../css/NavBarStyle.css';
import '../css/font-sizes.css';


const NavBar = () => {
    const location = useLocation();

    const isActive = (path) => {
        const fullPath = `${location.pathname}${location.hash}`;
        return fullPath === path ? 'active' : '';
    };

    const isSubMenuActive = (basePath) => {
        return location.pathname.startsWith(basePath) ? 'active' : '';
    };

    const scrollWithOffset = (el) => {
        const yOffset = -8 * 16; // 8rem in Pixel umrechnen (1rem = 16px)
        const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    useEffect(() => {
        // Collapse the navbar after each navigation
        const navbarCollapse = document.getElementById('navbarScroll');
        if (navbarCollapse && navbarCollapse.classList.contains('show')) {
            navbarCollapse.classList.remove('show');
        }
    }, [location]);

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-lg">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/"><img className="p-0 m-0" src={logo} alt="Limbach für Alle" /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarScroll">
                    <ul className="navbar-nav me-auto my-2 my-lg-0">
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive('/aktuelles') || isSubMenuActive('/post')}`} to="/aktuelles">Aktuelles</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive('/veranstaltungen')}`} to="/veranstaltungen">Veranstaltungen</Link>
                        </li>
                        <li className={`nav-item dropdown ${isSubMenuActive('/angebote')}`}>
                            <a className="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdownAngebote" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Angebote
                            </a>
                            <ul className="dropdown-menu bg-light" aria-labelledby="navbarScrollingDropdown">
                                <li><HashLink className={`dropdown-item ${isActive('/angebote#angebot1')}`} to="/angebote#angebot1" scroll={el => scrollWithOffset(el)}>Veranstaltungsraum Doro</HashLink></li>
                                <li><HashLink className={`dropdown-item ${isActive('/angebote#angebot2')}`} to="/angebote#angebot2" scroll={el => scrollWithOffset(el)}>Küche für Alle</HashLink></li>
                                <li><HashLink className={`dropdown-item ${isActive('/angebote#angebot3')}`} to="/angebote#angebot3" scroll={el => scrollWithOffset(el)}>Beratung</HashLink></li>
                            </ul>
                        </li>

                        <li className={`nav-item dropdown ${isSubMenuActive('/verein') || isSubMenuActive('/standorte') || isSubMenuActive('/mitmachen') || isSubMenuActive('/kontakt') || isSubMenuActive('/spende')}`}>
                            <a className="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Über uns
                            </a>
                            <ul className="dropdown-menu bg-light" aria-labelledby="navbarScrollingDropdown">
                                <li><Link className={`dropdown-item ${isActive('/verein')}`} to="/verein">Verein</Link></li>
                                <li><Link className={`dropdown-item ${isActive('/standorte')}`} to="/standorte" >Standorte</Link></li>
                                <li><Link className={`dropdown-item ${isActive('/mitmachen')}`} to="/mitmachen">Mitmachen</Link></li>
                                <li><Link className={`dropdown-item ${isActive('/kontakt')}`} to="/kontakt">Kontakt</Link></li>
                                <li><Link className={`dropdown-item ${isActive('/spende')}`} to="/spende">Spende</Link></li>
                            </ul>
                        </li>
                    </ul>
                    <div className="d-flex">
                        <SearchForm />
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
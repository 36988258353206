import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Kalender from './components/Kalender';

const VeranstaltungenSeite = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div id="WrapperVeranstaltungen" style={{ minHeight: '100vh' }}>
            <Helmet>
                <title>Veranstaltungen | Limbach für Alle</title>
            </Helmet>
            <div className="container" style={{ paddingTop: '8rem' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <h1 className='text-center'>Veranstaltungen</h1>
                        <hr style={{color: 'var(--primary-color)'}}/>
                        <p className='my-5'>
                        Hier findet ihr einen Überblick aller bevorstehenden Veranstaltungen unserer verschiedenen Projekte. <br />Ob Konzert, Vortrag oder Workshop – wir versuchen uns breit aufzustellen, dass für ALLE etwas dabei ist. Kommt doch gern vorbei. 
                        </p>
                        <Kalender />
                    </div>
                </div>
            </div>
        </div>

    )
};

export default VeranstaltungenSeite;